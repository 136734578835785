import { AddinSettings, OrganizationSettings } from '@/types/OrganizationSettings';
import { ActionContext } from 'vuex';
import OrganizationService from '@/services/OrganizationService';

interface OrganizationSettingsState {
  organizationSettings: OrganizationSettings | null;
}

const state: OrganizationSettingsState = {
  organizationSettings: null
};

const getters = {
  autoSeatAssignment: (state: OrganizationSettingsState): boolean => {
    return state.organizationSettings ? state.organizationSettings.autoSeatAssignment : false;
  },
  maxSeatsPerUser: (state: OrganizationSettingsState): number => {
    return state.organizationSettings ? state.organizationSettings.maxSeatsPerUser : 0;
  },
  addinSettings: (state: OrganizationSettingsState): AddinSettings | null => {
    return state.organizationSettings?.addinSettings ?? null;
  },
  licenseLimitations: (state: OrganizationSettingsState) => {
    return state.organizationSettings?.licenseLimitations ?? null;
  },
  usageStatistics: (state: OrganizationSettingsState) => {
    return state.organizationSettings?.usageStatistics ?? null;
  },
  licenseTier: (state: OrganizationSettingsState) => {
    return state.organizationSettings?.licenseTier ?? '';
  },
  licenseFeatureSettings: (state: OrganizationSettingsState) => {
    return state.organizationSettings?.featureSettings ?? null;
  }
};

const mutations = {
  SET_ORGANIZATION_SETTINGS(state: OrganizationSettingsState, organizationSettings: OrganizationSettings) {
    state.organizationSettings = organizationSettings;
  }
};

type OrganizationSettingsActionContext = ActionContext<OrganizationSettingsState, any>;

const actions = {
  async loadOrganizationSettings({ commit }: OrganizationSettingsActionContext, slug: string) {
    const settings = await OrganizationService.getSettings(slug);
    commit('SET_ORGANIZATION_SETTINGS', settings);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
};
