import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultInput = _resolveComponent("DefaultInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createElementVNode("span", {
        class: _normalizeClass(["text-sm ml-0.5", { 'text-error': _ctx.error, 'text-foreground-light': !_ctx.error }])
      }, _toDisplayString(_ctx.label), 3),
      _createVNode(_component_DefaultInput, {
        ref: "defaultInputRef",
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        error: _ctx.error,
        "max-length": _ctx.maxLength,
        accept: _ctx.accept,
        autocomplete: _ctx.autocomplete,
        disabled: _ctx.disabled,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event)))
      }, null, 8, ["modelValue", "type", "placeholder", "error", "max-length", "accept", "autocomplete", "disabled"])
    ]),
    (_ctx.info)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["text-xs", { 'text-error': _ctx.error, 'text-foreground-light': !_ctx.error }])
        }, _toDisplayString(_ctx.info), 3))
      : _createCommentVNode("", true)
  ]))
}