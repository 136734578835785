import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    "active-class": "link-active",
    class: _normalizeClass(_ctx.hovered ? 'hovered' : ''),
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hovered = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hovered = false))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VerticalStack, {
        alignment: "center",
        spacing: null
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["p-2 active-background default-transition", { 'p-2 rounded-xl': !_ctx.collapsed, 'p-1 rounded-lg': _ctx.collapsed }])
          }, [
            _createElementVNode("img", {
              src: _ctx.icon,
              class: _normalizeClass(["aspect-square active-icon icon default-transition", { 'w-6': !_ctx.collapsed, 'w-5': _ctx.collapsed }]),
              alt: "icon"
            }, null, 10, _hoisted_1)
          ], 2),
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(["active-title text-foreground text-center default-transition pt-1", { 'opacity-100': !_ctx.collapsed, 'opacity-0': _ctx.collapsed }])
              }, _toDisplayString(_ctx.title), 3)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to", "class"]))
}