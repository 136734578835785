import { useStore } from 'vuex';
import ModalInfo from '@/types/ModalInfo';
import { i18n } from '@/i18n/i18n-setup';

const useModal = () => {
  const store = useStore();

  const showModal = (
    {
      title,
      content = 'EmptyView',
      props = {},
      size = 'medium',
      showCloseButton = true,
      showMenu = true,
      closeOnEsc = false,
      onClose = () => void { },
    }:
      {
        title: string;
        content?: string;
        props?: Record<string, any>;
        size?: 'medium' | 'large' | 'small';
        showCloseButton?: boolean;
        showMenu?: boolean;
        closeOnEsc?: boolean;
        onClose?: () => void;
      },
  ) => {
    store.dispatch(
      'showModal',
      {
        title: i18n.global.t(title),
        content,
        props,
        size,
        showCloseButton,
        showMenu,
        closeOnEsc,
        onClose
      },
    );
  };

  const closeModal = async () => {
    const hook = store.getters.modalCloseHook;
    if (hook && !(await hook())) {
      return;
    }
    if (store.getters.modalInfo)
      (store.getters.modalInfo as ModalInfo).onClose();
    await store.dispatch('closeModal');
  };

  const onModalClose = (hook: () => Promise<boolean>) => {
    store.dispatch('setModalCloseHook', hook);
  }

  return {
    showModal,
    closeModal,
    onModalClose,
  };
};

export default useModal;
