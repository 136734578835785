import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BottomNavigationBarItem = _resolveComponent("BottomNavigationBarItem")!
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!

  return (_ctx.bottomBarVisible)
    ? (_openBlock(), _createBlock(_component_HorizontalStack, {
        key: 0,
        onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleMouse(true))),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMouse(false))),
        class: "justify-items-stretch w-full border-t border-border",
        spacing: null,
        alignment: "middle",
        ref: "bottomNavigationBar"
      }, {
        default: _withCtx(() => [
          (_ctx.imagesVisible)
            ? (_openBlock(), _createBlock(_component_BottomNavigationBarItem, {
                key: 0,
                title: _ctx.$t('screens.addin.bottomNavigation.images'),
                icon: "/icons/image.svg",
                to: "/addin/content/imagepool",
                class: "buttom_navigation_bar_item",
                collapsed: _ctx.collapsed && !_ctx.mouseIsHovering
              }, null, 8, ["title", "collapsed"]))
            : _createCommentVNode("", true),
          (_ctx.powerPointVisible)
            ? (_openBlock(), _createBlock(_component_BottomNavigationBarItem, {
                key: 1,
                title: _ctx.$t('screens.addin.bottomNavigation.powerPoint'),
                icon: "/icons/presentation.svg",
                to: "/addin/content/powerpoint",
                class: "buttom_navigation_bar_item",
                collapsed: _ctx.collapsed && !_ctx.mouseIsHovering
              }, null, 8, ["title", "collapsed"]))
            : _createCommentVNode("", true),
          (_ctx.iconsVisible)
            ? (_openBlock(), _createBlock(_component_BottomNavigationBarItem, {
                key: 2,
                title: _ctx.$t('screens.addin.bottomNavigation.icons'),
                icon: "/icons/icon.svg",
                to: "/addin/content/iconpool",
                class: "buttom_navigation_bar_item",
                collapsed: _ctx.collapsed && !_ctx.mouseIsHovering
              }, null, 8, ["title", "collapsed"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512))
    : _createCommentVNode("", true)
}