import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f15113f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-element" }
const _hoisted_2 = { class: "text-sm overflow-hidden text-center mx-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stack_spacer = _resolveComponent("stack-spacer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!

  return (_openBlock(), _createBlock(_component_HorizontalStack, {
    id: "tab-bar",
    spacing: null,
    alignment: "bottom"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_stack_spacer, {
        size: "extrasmall",
        class: "border-b border-border h-full"
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: tab.to,
          to: tab.to,
          "exact-active-class": "active-link",
          "active-class": "active-link",
          class: _normalizeClass(["overflow-hidden", _ctx.isActive(tab) ? 'active-link' : ''])
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(tab.title), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to", "class"]))
      }), 128)),
      _createVNode(_component_stack_spacer, {
        size: "stretch",
        class: "border-b border-border h-full"
      })
    ]),
    _: 1
  }))
}