import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createBlock(_component_default_button, {
    onClick: _ctx.logoutClicked,
    type: _ctx.buttonType,
    "auto-loading-state": "global",
    class: "px-4"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('components.logout.button')), 1)
    ]),
    _: 1
  }, 8, ["onClick", "type"]))
}