<template>
  <div class="">
    <label>
      <span
        class="text-sm ml-0.5"
        :class="{ 'text-error': error, 'text-foreground-light': !error }">
        {{ label }}
      </span>
      <DefaultInput
        ref="defaultInputRef"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :type="type"
        :placeholder="placeholder"
        :error="error"
        :max-length="maxLength"
        :accept="accept"
        :autocomplete="autocomplete"
        :disabled="disabled"
        @change="$emit('change', $event)"
      />
    </label>
    <span
      v-if="info"
      class="text-xs"
      :class="{ 'text-error': error, 'text-foreground-light': !error }"
    >
      {{ info }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
// eslint-disable-next-line import/no-duplicates
import DefaultInput from '@/components/controls/DefaultInput.vue';
// eslint-disable-next-line import/no-duplicates
import { commonDefaultInputProps } from '@/components/controls/DefaultInput.vue';

export default defineComponent({
  name: 'LabeledDefaultInput',
  components: {
    DefaultInput,
  },
  props: {
    ...commonDefaultInputProps,
    label: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'change'],
  setup() {
    const defaultInputRef = ref<InstanceType<typeof DefaultInput> | null>(null);

    function focus() {
      defaultInputRef.value?.focus();
    }

    return {
      defaultInputRef,
      focus,
    };
  },
});
</script>
