
const selectedSlide = async (
  onCurrentSlide: (slide: PowerPoint.Slide) => Promise<Boolean>,
  properties?: string[],
): Promise<Boolean> => {
  return currentPresentation(async (presentation) => {
    const slideList = presentation.getSelectedSlides();
    if (properties) {
      slideList.load(properties.map((property) => `items/${property}`));
    } else {
      slideList.load();
    }
    const slide = slideList.getItemAt(0);
    slide.load(properties);
    await presentation.context.sync();
    return await onCurrentSlide(slide);
  });
}

const selectedShape = async (
  onCurrentShape: (shape: PowerPoint.Shape) => Promise<Boolean>,
  properties?: string[],
): Promise<Boolean> => {

  return currentPresentation(async (presentation) => {
    const shapeList = presentation.getSelectedShapes();

    if (properties) {
      shapeList.load(properties.map((property) => `items/${property}`));
    } else {
      shapeList.load();
    }

    await presentation.context.sync();

    if (shapeList.items.length === 0) {
      return false;
    }

    const shape = shapeList.getItemAt(0);

    shape.load(properties);

    await presentation.context.sync();

    return await onCurrentShape(shape);
  });
}

const currentPresentation = async (
  onCurrentPresentation: (presentation: PowerPoint.Presentation) => Promise<Boolean>,
  properties?: string | string[],
): Promise<Boolean> => {
  return new Promise<Boolean>((resolve, reject) => {
    PowerPoint.run(async (context) => {
      try {
        context.presentation.load(properties);
        const presentation = context.presentation;
        await context.sync();

        const result = await onCurrentPresentation(presentation);
        resolve(result);
      } catch (error) {
        console.error('Error: ', (error as Error).message);
        if (error instanceof OfficeExtension.Error) {
          console.log("Error Name: " + error.name);
          console.log("Error Message: " + error.message);
          console.log("Error Debug Info: " + JSON.stringify(error.debugInfo));
        }
        reject(error);
      }
    });
  });
}

const openExternalUrl = (url: string) => {
  if (!Office.context.ui || !Office.context.ui.openBrowserWindow) {
    window.open(url, '_blank');
    return;
  }
  Office.context.ui.openBrowserWindow(url);
}

export {
  selectedSlide,
  selectedShape,
  currentPresentation,
  openExternalUrl,
}
