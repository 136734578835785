import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 6,
  class: "pt-4 text-foreground-light text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_labeled_default_input = _resolveComponent("labeled-default-input")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_default_checkbox = _resolveComponent("default-checkbox")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_auth_view_holder = _resolveComponent("auth-view-holder")!

  return (_openBlock(), _createBlock(_component_auth_view_holder, {
    title: _ctx.$t('screens.login.title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
      }, [
        (_ctx.showCredentialsLogin)
          ? (_openBlock(), _createBlock(_component_VerticalStack, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_labeled_default_input, {
                  class: "w-full",
                  label: _ctx.$t('screens.login.email.label'),
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  type: "email",
                  error: _ctx.onceSent && _ctx.email === ''
                }, null, 8, ["label", "modelValue", "error"]),
                _createVNode(_component_labeled_default_input, {
                  class: "w-full",
                  label: _ctx.$t('screens.login.password.label'),
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                  type: "password",
                  error: _ctx.onceSent && _ctx.password === ''
                }, null, 8, ["label", "modelValue", "error"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.showCredentialsLogin)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.inOfficeAddin ? '/icons/powerpoint.svg' : '/icons/microsoft.svg',
              class: "w-20 h-20 mx-auto"
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (!_ctx.inOfficeAddin)
          ? (_openBlock(), _createBlock(_component_default_checkbox, {
              key: 2,
              class: "pt-6",
              modelValue: _ctx.rememberMe,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rememberMe) = $event)),
              label: _ctx.$t('screens.login.stayLoggedIn.label'),
              size: "large"
            }, null, 8, ["modelValue", "label"]))
          : _createCommentVNode("", true),
        (_ctx.showCredentialsLogin)
          ? (_openBlock(), _createBlock(_component_default_button, {
              key: 3,
              actionType: "submit",
              label: _ctx.$t('screens.login.buttons.login'),
              class: "w-full mt-6",
              "auto-loading-state": "global"
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (!_ctx.inOfficeAddin)
          ? (_openBlock(), _createBlock(_component_default_button, {
              key: 4,
              label: _ctx.$t('screens.login.buttons.loginWithMicrosoft'),
              class: "w-full mt-6",
              "auto-loading-state": "global",
              onClick: _ctx.loginWithMicrosoft
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.inOfficeAddin)
          ? (_openBlock(), _createBlock(_component_default_button, {
              key: 5,
              label: _ctx.$t('screens.login.buttons.loginWithPowerPoint'),
              class: "w-full mt-6",
              "auto-loading-state": "global",
              onClick: _ctx.loginWithPowerPoint
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.inOfficeAddin)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('screens.login.labels.powerPointLoginHint')), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_VerticalStack, {
          class: "w-full text-center mt-6",
          alignment: "center"
        }, {
          default: _withCtx(() => [
            (!_ctx.showCredentialsLogin)
              ? (_openBlock(), _createBlock(_component_default_button, {
                  key: 0,
                  type: "link",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCredentialsLogin = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('screens.login.buttons.useCredentials')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.inOfficeAddin)
              ? (_openBlock(), _createBlock(_component_default_button, {
                  key: 1,
                  type: "link",
                  onClick: _ctx.openRegisterInBrowser,
                  "auto-loading-state": "global"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('screens.login.links.register')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (_openBlock(), _createBlock(_component_default_button, {
                  key: 2,
                  "to-route": "/register",
                  "auto-loading-state": "global"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('screens.login.links.register')), 1)
                  ]),
                  _: 1
                })),
            (_ctx.showCredentialsLogin)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  (_ctx.inOfficeAddin)
                    ? (_openBlock(), _createBlock(_component_default_button, {
                        key: 0,
                        type: "link",
                        onClick: _ctx.openForgotPasswordInBrowser,
                        "auto-loading-state": "global"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('screens.login.links.forgotPassword')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_default_button, {
                        key: 1,
                        "to-route": "/forgot-password",
                        "auto-loading-state": "global"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('screens.login.links.forgotPassword')), 1)
                        ]),
                        _: 1
                      }))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 32)
    ]),
    _: 1
  }, 8, ["title"]))
}