import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/payment/ProductSelectionView.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'screens.payment.products.title',
    },
  },
];

export default routes;
