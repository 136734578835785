import axios, { AxiosResponse, AxiosRequestHeaders } from 'axios';
import AuthService from '@/services/AuthService';
import { convertIsoStringsToDate } from '@/utils/date-formatters';
import { api, system } from './config';
import { isPowerPoint } from '@/office-checker';
import { LocalSettingsService } from '@/services/LocalSettingsService';

if (system.doAxiosDelay) {
  axios.interceptors.request.use(request => {
    return new Promise(resolve => {
      setTimeout(() => resolve(request), 2000); // 2000 Millisekunden Delay
    });
  });
}

function getCookieExpires(minutes: number) {
  const date = new Date();
  date.setTime(date.getTime() + (minutes * 60 * 1000)); // Setzt die Zeit in Millisekunden
  return date.toUTCString();
}

axios.interceptors.request.use( async request => {
  const hostname = window.location.hostname;
  const topLevelDomain = hostname.substring(hostname.lastIndexOf(".", hostname.lastIndexOf(".") - 1) + 1);
  const cookieDomain = `.${topLevelDomain}`;
  document.cookie = `uniqueVisitorDeviceId_slideroom=${await LocalSettingsService.getUniqueClientIdentifier()}; path=/; domain=${cookieDomain}; expires=${getCookieExpires(10)};`
  return request;
})

axios.interceptors.request.use(
  (config) => {
    const auth = api.basicUser && api.basicPassword ? `Basic ${btoa(`${api.basicUser}:${api.basicPassword}`)}` : null;
    const headers = auth
      ? { ...config.headers, Authorization: auth } as AxiosRequestHeaders
      : config.headers;
    const isBackend = config.url?.startsWith(api.v1) ?? false;
    return {
      ...config,
      headers,
      withCredentials: isBackend,
    };
  },
  (error) => Promise.reject(error),
);

axios.interceptors.request.use(
  (config) => {
    if (isPowerPoint) {
      config.headers['X-PowerPoint-Addin'] = 'true';
    }

    return config;
  },
  (error) => Promise.reject(error),
);

let refreshPromise: Promise<any> | null = null;

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // eslint-disable-next-line no-param-reassign
    response.data = convertIsoStringsToDate(response.data);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response
      && error.response.status === 401
      && !originalRequest.retry
      && originalRequest.url !== `${api.v1}/auth/signup`
      && originalRequest.url !== `${api.v1}/auth/signin`
      && originalRequest.url !== `${api.v1}/auth/refresh`
      && originalRequest.url !== `${api.v1}/me/verify`
      && originalRequest.url !== `${api.v1}/signin`
      && originalRequest.url !== `${api.v1}/auth/microsoft/callback`
    ) {
      if (refreshPromise === null) {
        refreshPromise = AuthService.refresh().finally(() => {
          refreshPromise = null; // Wichtig, um den Lock nach Abschluss zu löschen.
        });
      }

      try {
        await refreshPromise; // Warten auf den laufenden oder neuen Refresh-Token-Vorgang.
        originalRequest.retry = true;
        return axios(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);
