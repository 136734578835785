const upperSnakeCaseToCamelCase = (input: string): string => input
  .toLowerCase()
  .split('_')
  .map((part, index) => {
    if (index === 0) {
      return part;
    }
    return part.charAt(0).toUpperCase() + part.slice(1);
  })
  .join('');

const camelCaseToUpperSnakeCase = (input: string): string => input
  .split('')
  .map((char) => {
    if (char === char.toUpperCase()) {
      return `_${char}`;
    }
    return char;
  })
  .join('')
  .toUpperCase();

const formatCurrency = (valueInCents: number, currencyCode: string): string => {
  const amount = valueInCents / 100;
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
  });
  return formatter.format(amount);
}

const formatBytes = (bytes: number, unit?: 'Bytes' | 'KB' | 'MB' | 'GB'): string => {
  if (unit) {
    switch (unit) {
      case 'Bytes':
        return `${bytes} Bytes`;
      case 'KB':
        return `${(bytes / 1024).toFixed(bytes % 1 === 0 ? 0 : 1)} KB`;
      case 'MB':
        return `${(bytes / (1024 * 1024)).toFixed(bytes % 1 === 0 ? 0 : 1)} MB`;
      case 'GB':
        return `${(bytes / (1024 * 1024 * 1024)).toFixed(bytes % 1 === 0 ? 0 : 1)} GB`;
      default:
        throw new Error(`Ungültiges Format '§{unit}'. Verfügbare Formate sind Bytes, KB, MB, GB.`);
    }
  } else {
    if (bytes < 1024) {
      return `${bytes} Bytes`;
    } else if (bytes < 1048576) {
      return `${Math.floor(bytes / 1024)} KB`;
    } else if (bytes < 1073741824) {
      return `${(bytes / 1048576).toFixed(bytes % 1 === 0 ? 0 : 1)} MB`;
    } else {
      return `${(bytes / 1073741824).toFixed(bytes % 1 === 0 ? 0 : 1)} GB`;
    }
  }
};



export {
  upperSnakeCaseToCamelCase,
  camelCaseToUpperSnakeCase,
  formatBytes,
  formatCurrency,
};
