import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, [
      _createTextVNode(" Dies ist ein Platzhalter... Normalerweise wäre hier "),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.route.name), 1)
    ])
  ]))
}