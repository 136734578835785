import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_LogoutButtonComponent = _resolveComponent("LogoutButtonComponent")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_SimpleModal = _resolveComponent("SimpleModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, { id: "addin" }),
    (!_ctx.organizationName)
      ? (_openBlock(), _createBlock(_component_SimpleModal, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_VerticalStack, {
              alignment: "center",
              class: "p-4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('screens.addin.noOrganizationInfo.info')), 1),
                _createVNode(_component_default_button, { onClick: _ctx.createOrganization }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('screens.addin.noOrganizationInfo.button')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_default_button, {
                  type: "constructive",
                  onClick: _ctx.organizationCreated
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('screens.addin.noOrganizationInfo.reload')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_LogoutButtonComponent)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}