import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalView = _resolveComponent("ModalView")!

  return (_openBlock(), _createBlock(_component_ModalView, {
    title: _ctx.modal.title,
    onClose: _ctx.removeModal,
    size: _ctx.modal.size,
    "show-close-button": _ctx.modal.showCloseButton,
    "show-menu": _ctx.modal.showMenu,
    "close-on-escape": _ctx.modal.closeOnEsc
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.loadModalComponent), _normalizeProps(_guardReactiveProps(_ctx.modal.props)), null, 16))
    ]),
    _: 1
  }, 8, ["title", "onClose", "size", "show-close-button", "show-menu", "close-on-escape"]))
}