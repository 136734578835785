import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStack = _resolveComponent("VerticalStack")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["content-card ml-4 mr-2 mb-4", [
        _ctx.menuSize === 'small' ? 'w-72' : 'min-w-1/3 w-1/3',
        'overflow-auto flex-shrink-0'
      ]])
    }, [
      _createVNode(_component_VerticalStack, {
        class: "p-6",
        spacing: null
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "menu", { class: "flex-grow" })
        ]),
        _: 3
      })
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex-grow content-card mr-4 ml-2 mb-4", {
        'overflow-auto': _ctx.contentScrollable,
        'overflow-hidden': !_ctx.contentScrollable,
      }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["p-6", {
          'h-full': !_ctx.contentScrollable,
        }])
      }, [
        _renderSlot(_ctx.$slots, "content")
      ], 2)
    ], 2)
  ]))
}