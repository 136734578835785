import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_labeled_default_input = _resolveComponent("labeled-default-input")!

  return (_openBlock(), _createBlock(_component_labeled_default_input, {
    class: "w-full",
    label: _ctx.label,
    type: "file",
    accept: _ctx.accept,
    onChange: _ctx.handleFileChange
  }, null, 8, ["label", "accept", "onChange"]))
}