import store from '@/store/store';
import User from '@/types/User';

type SettingsKey = 'displayStyle' | 'loginStateIntention' | 'uniqueClientIdentifier' | 'organizationCreationSkipped';
type DisplayStyle = 'list' | 'grid';
type LoginStateIntention = 'intentionallyLoggedOut' | 'selfLoggedIn';

class LocalSettingsService {
  static async getDisplayStyle(defaultStyle?: DisplayStyle): Promise<DisplayStyle> {
    const storedStyle = localStorage.getItem(this.userBasedKey('displayStyle')) as DisplayStyle;
    return storedStyle !== null ? storedStyle : (defaultStyle || 'grid');
  }

  static setDisplayStyle(value: DisplayStyle) {
    localStorage.setItem(this.userBasedKey('displayStyle'), value);
  }

  static setOrganizationCreationSkipped() {
    localStorage.setItem(this.userBasedKey('organizationCreationSkipped'), 'true');
  }

  static getOrganizationCreationSkipped(): boolean {
    return localStorage.getItem(this.userBasedKey('organizationCreationSkipped')) === 'true';
  }

  static getUniqueClientIdentifier(): string {
    return localStorage.getItem('uniqueClientIdentifier') || '';
  }

  static setUniqueClientIdentifier(value: string) {
    localStorage.setItem('uniqueClientIdentifier', value);
  }

  static getLoginStateIntention(): LoginStateIntention | null {
    return localStorage.getItem('loginStateIntention') as LoginStateIntention | null;
  }

  static setLoginStateIntention(value: LoginStateIntention | null) {
    if (value) {
      localStorage.setItem('loginStateIntention', value);
    } else {
      localStorage.removeItem('loginStateIntention');
    }
  }

  private static userBasedKey(key: SettingsKey): string {
    const user = store.getters.user as User;
    if (!user) {
      return key;
    }
    return `${user.reference}-${key}`;
  }
}

export {
  LocalSettingsService,
  DisplayStyle,
}
