import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_button = _resolveComponent("default-button")!
  const _component_WrappingFlow = _resolveComponent("WrappingFlow")!

  return (_openBlock(), _createBlock(_component_WrappingFlow, { gap: _ctx.gap }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadCrumbs, (breadCrumb, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (breadCrumb.path === _ctx.currentPath || breadCrumb.path == 0)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["px-2 font-bold", {
          'text-sm': _ctx.size === 'small',
        }])
              }, _toDisplayString(breadCrumb.name), 3))
            : (_openBlock(), _createBlock(_component_default_button, {
                key: 1,
                type: "link",
                class: _normalizeClass(["p-0 m-0", {
          'text-sm': _ctx.size === 'small',
        }]),
                onClick: ($event: any) => (_ctx.goBackTo(breadCrumb))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(breadCrumb.name), 1)
                ]),
                _: 2
              }, 1032, ["class", "onClick"])),
          (index < _ctx.breadCrumbs.length - 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, ">"))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _: 1
  }, 8, ["gap"]))
}