import { RouteRecordRaw } from 'vue-router';
import AddInView from '@/views/addin/AddInView.vue';
import store from '@/store/store';
import { AddinSettings } from '@/types/OrganizationSettings';

const getRedirectBaseLogic = (): string | null => {
  const addinSettings = store.getters.addinSettings as AddinSettings | null;
  if (!store.getters.user) {
    return null;
  }
  if (!addinSettings) {
    return '/addin/content/images';
  }
  if (!addinSettings.imagesHidden)
    return '/addin/content/images';
  else if (!addinSettings.onlineImagesHidden)
    return '/addin/content/onlineImages';
  else if (!addinSettings.presentationsHidden)
    return '/addin/content/powerpoint';
  else if (!addinSettings.slidesHidden)
    return '/addin/content/slides';
  else if (!addinSettings.iconsHidden)
    return '/addin/content/icons';
  else if (!addinSettings.animatedIconsHidden)
    return '/addin/content/animated_icons';

  return null;
};

const addInRoutes: Array<RouteRecordRaw> = [
  {
    path: '/addin',
    name: 'addin',
    component: AddInView,
    beforeEnter: (to, from, next) => {
      if (to.path.includes('/addin/content/')) {
        next();
        return;
      }
      const dynamicRedirect = getRedirectBaseLogic();
      if (dynamicRedirect) {
        next(dynamicRedirect);
      } else {
        console.error('No dynamicRedirect found. Navigating to loading...');
        next('/loading')
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'content',
        name: 'addin-content-images',
        component: () => import('@/views/addin/content/ContentView.vue'),
        children: [
          {
            path: 'favorites/:type',
            name: 'addin-content-favorites',
            component: () => import('@/views/addin/content/ContentFavoritesDetailsView.vue'),
          },
          {
            path: 'last-used/:type',
            name: 'addin-content-last-used',
            component: () => import('@/views/addin/content/ContentLastUsedDetailsView.vue'),
          },
          {
            path: ':type',
            name: 'addin-content-type',
            component: () => import('@/views/addin/content/ContentFilesView.vue'),
          },
          {
            path: ':type/:categorySlug',
            name: 'addin-content-category',
            component: () => import('@/views/addin/content/ContentCategoryDetailsView.vue'),
          },
        ],
      }
    ]
  },
];

export default addInRoutes;

export { getRedirectBaseLogic };
