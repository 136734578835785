import { deleteCookie, getCookie } from '@/utils/cookie-helpers';
import { api, system } from '@/config';
import User from '@/types/User';
import axios from 'axios';
import store from '@/store/store';
import { appInsights, createAppInsights, removeAppInsights } from '@/utils/azure-appinsights';

const ConsentsService = {

  async enableTracking() {
    await axios.patch(`${api.v1}/me/settings/tracking-consent`, { trackingConsent: true });
    await store.dispatch('loadUser')
  },

  async disableTracking() {
    await axios.patch(`${api.v1}/me/settings/tracking-consent`, { trackingConsent: false });
    await store.dispatch('loadUser')
  },

  async enableMailing() {
    await axios.patch(`${api.v1}/me/settings/mailing-consent`, { mailingConsent: true });
    await store.dispatch('loadUser')
  },

  async disableMailing() {
    await axios.patch(`${api.v1}/me/settings/mailing-consent`, { mailingConsent: false });
    await store.dispatch('loadUser')
  },

  enableAppInsightsTracking(user: User) {
    if (appInsights) return;
    const createdAppInsights = createAppInsights();
    createdAppInsights.config.disableTelemetry = false;
    createdAppInsights.config.disableCookiesUsage = false;
    createdAppInsights.loadAppInsights();
    this.setTrackedUserIfAllowed(user);
  },

  disableAppInsightsTracking() {
    if (!appInsights) return;
    appInsights.config.disableTelemetry = true;
    appInsights.config.disableCookiesUsage = true;
    this.setTrackedUserIfAllowed();
    this.deleteAppInsightsCookies();
    removeAppInsights();
  },

  deleteAppInsightsCookies () {
    const cookies = document.cookie.split('; ');
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name.startsWith('ai_')) { // Assuming App Insights cookies start with 'ai_'
        deleteCookie(name);
      }
    });
  },

  intentionallyCrash() {
    throw new Error('Intentionally crashing the app');
  },

  async intentionallyCrashBackend() {
    await axios.post(`${api.v1}/config/crash`);
  },

  setTrackedUserIfAllowed(user?: User) {
    if (!appInsights) return;
    if (user) {
      appInsights.setAuthenticatedUserContext(user.reference);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  }

}

export default ConsentsService;
