import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_content = _resolveComponent("centered-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["absolute top-0 bottom-0 inset-0", {
      'bg-blurry': _ctx.theme === 'light',
      'bg-blurry-dark': _ctx.theme === 'dark',
    }])
  }, [
    _createVNode(_component_centered_content, { class: "w-full h-full overflow-hidden py-8" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ], 2))
}