<template>
  <div>
    <div class="w-screen h-screen overflow-auto">
      <div class="mx-auto py-10"
        :class="{
          'max-w-xs': inOfficeAddin(),
          'w-96': !inOfficeAddin(),
        }"
      >
        <div class="text-center">
          <img src="/logo.png" alt="Logo" class="inline-block w-64 mb-8" />
        </div>
        <card-element :class="{
      'mx-2': inOfficeAddin(),
    }">
          <h1 class="text-left">{{ title }}</h1>
          <slot></slot>
        </card-element>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { inOfficeAddin } from '@/office-checker';

export default defineComponent({
  methods: {
    inOfficeAddin() {
      return inOfficeAddin
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
});
</script>
