<template>
  <div class="flex flex-row"
    :class="[spacingClass, {
      'items-start': alignment === 'top',
      'items-center': alignment === 'middle',
      'items-end': alignment === 'bottom',
      'items-baseline': alignment === 'baseline',
      'items-first-baseline': alignment === 'first-baseline',
      'items-stretch': alignment === 'stretch',
    }]"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, provide } from 'vue';
import { getSpacingClass, Spacing } from '@/router/spacing-helpers';

export default defineComponent({
  name: 'HorizontalStack',
  props: {
    alignment: {
      type: String as PropType<'top' | 'middle' | 'bottom' | 'baseline' | 'stretch'>,
      default: 'top',
    },
    spacing: {
      type: Number as PropType<Spacing | null>,
      default: 4,
    },
  },
  setup(props) {
    provide('isVerticalStack', true);

    const spacingClass = computed(() => {
      if (props.spacing === null) {
        return '';
      } else {
        return getSpacingClass(props.spacing, 'x');
      }
    });
    return {
      spacingClass,
    };
  },
});
</script>
