import { RouteRecordRaw } from 'vue-router';
import ProfileView from '@/views/admin/profile/ProfileView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'me',
    name: 'me',
    component: ProfileView,
    meta: {
      requiresAuth: true,
      titleKey: 'screens.profile.title',
      permissions: ['USER_READ_SELF'],
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@/views/admin/profile/ProfileEditDataView.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['USER_EDIT_SELF', 'USER_READ_SELF'],
        },
      },
      {
        path: 'password',
        name: 'password',
        component: () => import('@/views/admin/profile/ProfileAuthenticationView.vue'),
        meta: {
          requiresAuth: true,
          titleKey: 'screens.profile.authentication.title',
          permissions: ['USER_CHANGE_PASSWORD', 'USER_EDIT_SELF'],
        },
      },
      {
        path: 'active-sessions',
        name: 'active-sessions',
        component: () => import('@/views/admin/profile/ProfileActiveSessionsView.vue'),
        meta: {
          requiresAuth: true,
          titleKey: 'screens.profile.activeLogins.title',
          permissions: ['USER_READ_SELF', 'USER_ACTIVE_LOGINS_READ_SELF'],
        },
      }
    ],
  },
];

export default routes;
