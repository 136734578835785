import RegisterView from '@/views/general/RegisterView.vue';
import RegisterDataView from '@/views/general/RegisterDataView.vue';
import RegisterEmailVerificationView from '@/views/general/RegisterEmailVerificationView.vue';
import VerifyEmailView from '@/views/general/VerifyEmailView.vue';
import { RouteRecordRaw } from 'vue-router';
import ForgotPasswordView from '@/views/general/ForgotPasswordView.vue';

const registerRoutes: Array<RouteRecordRaw> = [
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      requiresAuth: false,
      titleKey: 'screens.registration.title',
    },
    children: [
      {
        path: '',
        name: 'registerData',
        component: RegisterDataView,
        meta: {
          requiresAuth: false,
          titleKey: 'screens.registration.data.title',
        },
      },
      {
        path: 'emailnotverified',
        name: 'emailNotVerified',
        component: RegisterEmailVerificationView,
        meta: {
          requiresAuth: false,
          titleKey: 'screens.registration.emailVerification.title',
        },
      },
      {
        path: 'verify_email/:token',
        name: 'verifyEmail',
        component: VerifyEmailView,
        meta: {
          requiresAuth: false,
          titleKey: 'screens.registration.verified.title',
        },
      },
      {
        path: "create-org",
        name: 'create-org',
        component: () => import('@/views/admin/organization/CreateOrganizationAuthView.vue'),
        meta: {
          requiresAuth: true,
          titleKey: 'screens.createEditOrganization.createTitle',
          permissions: ['ORGANIZATION_CREATE'],
        },
      },
    ],
  },
];

export default registerRoutes;
