import { api, stripe as stripeConfig } from '@/config';
import { loadStripe, StripeEmbeddedCheckout } from '@stripe/stripe-js';
import axios from 'axios';
import Product from '@/types/Product';

type TierQuantity = 3 | 5 | 10 | 15 | 20 | 30 | 50 | 100 | 150 | 200 | 300 | 400 | 500;
type TierInterval = 'month' | 'year';

const PaymentService = {
  async fetchClientSecret(tierId: string, quantity: TierQuantity, interval: TierInterval): Promise<string> {
    const response = await axios.post<CheckoutCreateResponse>(
      `${api.v1}/checkout/session/${tierId}`,
      {
        returnUrl: `${window.location.origin}/payment_checkout_result`,
        quantity,
        interval
      },
    )

    return response.data.clientSecret;
  },

  async createCheckout(tierId: string, quantity: TierQuantity, interval: TierInterval): Promise<StripeEmbeddedCheckout | undefined> {
    const stripe = await loadStripe(stripeConfig.publishableKey);

    return stripe?.initEmbeddedCheckout({
      fetchClientSecret: () => this.fetchClientSecret(tierId, quantity, interval),
    });

  },

  async fetchCheckoutSession(sessionId: string): Promise<CheckoutSession> {
    const response = await axios.get<CheckoutSession>(
      `${api.v1}/checkout/session/${sessionId}`
    );

    return response.data;
  },

  async fetchProducts(
    includePrices: boolean = true,
  ): Promise<Product[]> {
    const response = await axios.get<Product[]>(
      `${api.v1}/checkout/products`,
      {
        params: {
          include_prices: includePrices,
        },
      }
    );

    return response.data;
  },

  async storeUserPlanSelection(plan: string, quantity: TierQuantity, interval: TierInterval): Promise<void> {
    sessionStorage.setItem(
      'planPreSelection',
      JSON.stringify({
        plan,
        quantity,
        interval
      })
    );
  },

  getUserPlanSelection(): UserPlanSelection | null {
    const planPreSelection = sessionStorage.getItem('planPreSelection');

    if (!planPreSelection) {
      return null;
    }

    return JSON.parse(planPreSelection);
  },

  clearUserPlanSelection() {
    sessionStorage.removeItem('planPreSelection');
  },
};

interface UserPlanSelection {
  plan: string;
  quantity: TierQuantity;
  interval: TierInterval;
}

interface CheckoutCreateResponse {
  clientSecret: string;
}

export default PaymentService;

export {
  TierQuantity,
  TierInterval,
}
