import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.buttonTag), {
    type: _ctx.actionType,
    "onClick:stop": _cache[0] || (_cache[0] = ($event: any) => (_ctx.disabled || _ctx.autoLoadingDisabled ? () => {} : _ctx.onClick)),
    disabled: _ctx.disabled || _ctx.autoLoadingDisabled,
    to: _ctx.toRoute,
    class: _normalizeClass(_ctx.buttonClasses),
    ref: "button"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t(_ctx.label)), 1),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 40, ["type", "disabled", "to", "class"]))
}