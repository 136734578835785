import { vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-sm text-foreground-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_HorizontalStack = _resolveComponent("HorizontalStack")!

  return (_openBlock(), _createBlock(_component_HorizontalStack, {
    alignment: "top",
    spacing: 2
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
        class: _normalizeClass({ 'size-5 grow-0 shrink-0': _ctx.size === 'large',})
      }, null, 2), [
        [_vModelCheckbox, _ctx.checked]
      ]),
      _createVNode(_component_VerticalStack, { spacing: null }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
          (_ctx.info)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.info), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}