<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { AlertInfo } from '@/types/AlertInfo';
import { useStore } from 'vuex';
import VerticalStack from '@/components/layout/VerticalStack.vue';
import CodeInput from '@/components/controls/CodeInput.vue';
import LabeledDefaultInput from '@/components/controls/LabeledDefaultInput.vue';
import DefaultInput from '@/components/controls/DefaultInput.vue';
import OverlayBackground from '@/components/layout/OverlayBackground.vue';

export default defineComponent({
  name: 'AlertDialog',
  components: {OverlayBackground, CodeInput, VerticalStack },
  props: {
    alert: {
      type: Object as () => AlertInfo,
      required: true,
    },
  },
  setup: (props) => {
    const store = useStore();

    const errorMessage = ref('');
    const input = ref(props.alert.prefill || '');

    const inputElement = ref<InstanceType<typeof LabeledDefaultInput | typeof DefaultInput | typeof CodeInput> | null>(null);

    const buttonIndexClicked = async (index: number) => {
      const button = props.alert?.buttons[index];
      if (button?.action) {
        const result = await button.action(
          input.value,
        );
        if (result !== false) {
          await store.dispatch('closeAlert');
        }
      }
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        buttonIndexClicked(props.alert.buttons.findIndex((button) => button.type === 'primary'));
      }
      if (event.key === 'Escape') {
        buttonIndexClicked(props.alert.buttons.findIndex((button) => button.isCancel));
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', handleKeydown);
      inputElement.value?.focus();
    });

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeydown);
    });

    watch(
      () => store.state.alertError,
      (newErrorMessage) => {
        errorMessage.value = newErrorMessage;
      },
    );

    return {
      buttonIndexClicked,
      errorMessage,
      input,
      inputElement,
    };
  },
});
</script>

<template>
  <OverlayBackground class="z-30">
    <card-element class="sm:w-5/6 md:w-1/2 lg:w-1/3 w-5/6">
      <VerticalStack>
        <h2 v-if="alert.title">{{ alert.title }}</h2>
        <p v-if="alert.message" v-safe-html="alert.message"/>
        <CodeInput
          v-if="alert.type === 'code'"
          class="w-[270px] mx-auto"
          v-model="input"
          ref="inputElement"
        />
        <labeled-default-input
          v-if="alert.type === 'text' && alert.textLabel"
          class="w-full"
          v-model="input"
          :type="alert.inputType"
          :label="alert.textLabel"
          ref="inputElement"
        />
        <default-input
          v-if="alert.type === 'text' && !alert.textLabel"
          class="w-full"
          :type="alert.inputType"
          v-model="input"
          ref="inputElement"
        />
        <p class="text-error">{{ errorMessage }}</p>
        <WrappingFlow gap="small" class="justify-center sm:justify-start">
          <div v-for="(button, index) in alert.buttons" :key="index"
            class="w-full sm:w-auto"
          >
            <default-button
              :label="button.title"
              class="w-full"
              :type="button.type"
              :auto-loading-state="button.autoLoadingState"
              :onClick="() => buttonIndexClicked(index)"
            />
          </div>
        </WrappingFlow>
      </VerticalStack>
    </card-element>
  </OverlayBackground>
</template>
