import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w-screen h-screen overflow-hidden relative",
  id: "main-container"
}
const _hoisted_2 = {
  key: 2,
  class: "fixed bottom-0 left-1/2 transform -translate-x-1/2 p-4 bg-gray-200 z-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalViewWrapper = _resolveComponent("ModalViewWrapper")!
  const _component_AlertDialog = _resolveComponent("AlertDialog")!
  const _component_message_component = _resolveComponent("message-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading_view = _resolveComponent("loading-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modal)
      ? (_openBlock(), _createBlock(_component_ModalViewWrapper, {
          key: 0,
          modal: _ctx.modal,
          class: "absolute z-20"
        }, null, 8, ["modal"]))
      : _createCommentVNode("", true),
    (_ctx.alert)
      ? (_openBlock(), _createBlock(_component_AlertDialog, {
          key: 1,
          alert: _ctx.alert
        }, null, 8, ["alert"]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage || _ctx.successMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_message_component, {
            message: _ctx.errorMessage,
            class: "mb-4",
            type: "error",
            "auto-remove": "",
            onRemove: _ctx.clearErrorMessage
          }, null, 8, ["message", "onRemove"]),
          _createVNode(_component_message_component, {
            message: _ctx.successMessage,
            class: "mb-4",
            type: "success",
            "auto-remove": "",
            onRemove: _ctx.clearSuccessMessage
          }, null, 8, ["message", "onRemove"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 3,
          class: "relative z-0 overflow-visible"
        }))
      : (_openBlock(), _createBlock(_component_loading_view, { key: 4 }))
  ]))
}