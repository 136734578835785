import { createI18n } from 'vue-i18n';
import compiledLocales from './compiledLocales.json';

const messages = compiledLocales;

const browserLanguage = navigator.language;

const i18n = createI18n({
  locale: browserLanguage,
  fallbackLocale: 'de',
  messages,
  legacy: false,
  silentFallbackWarn: true,
});

const t = i18n.global.t;

export {
  i18n,
  t,
};
