<template>
  <component
    :is="buttonTag"
    :type="actionType"
    @click:stop="disabled || autoLoadingDisabled ? () => {} : onClick"
    :disabled="disabled || autoLoadingDisabled"
    :to="toRoute"
    :class="buttonClasses"
    ref="button"
  >
    {{ $t(label) }}<slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from 'vuex';
import LoadingContext from '@/types/LoadingContext';

const notProvided = Symbol('notProvided');

type ButtonType = 'primary' | 'secondary' | 'destructive' | 'link' | 'icon' | 'constructive' | 'secondaryLink';

export default defineComponent({
  name: 'DefaultButton',
  props: {
    actionType: {
      type: String as PropType<'button' | 'submit'>,
      default: 'button',
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    type: {
      type: String as PropType<ButtonType>,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoLoadingState: {
      type: String as PropType<LoadingContext | null>,
      default: null,
    },
    toRoute: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<'small' | 'large'>,
      default: 'large',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const button = ref<HTMLElement | null>();

    const autoLoadingDisabled = computed(() => props.autoLoadingState && store.getters.isLoading(props.autoLoadingState));

    const onClick = () => {
      if (props.disabled || autoLoadingDisabled.value) return;
      emit('click');
      if (button.value) button.value?.blur();
    };

    const buttonTag = computed(() => {
      if (props.toRoute) {
        if (props.disabled || autoLoadingDisabled.value) {
          return 'p';
        }
        return 'router-link';
      }
      return 'button';
    });

    const baseClasses = props.size === 'small' ? 'py-1 px-1 min-w-24' : 'py-2 px-6 min-w-24';
    const getDestructiveClasses = () => 'button destructive rounded-lg';

    const getLinkClasses = () => 'link default px-2';

    const getSecondaryLinkClasses = () => 'link secondary px-2';

    const spanClasses = 'bg-transparent text-primary-light';

    const getDefaultClasses = () => 'button default rounded-lg';

    const getSecondaryClasses = () => 'button secondary rounded-lg';

    const getConstructiveClasses = () => 'button constructive rounded-lg';

    const getIconClasses = () => 'bg-transparent text-primary '
      + 'hover:text-primary-dark disabled:text-primary-light';

    const buttonClasses = computed(() => {
      let classSet = '';

      if (props.type === 'destructive') {
        classSet += `${baseClasses} ${getDestructiveClasses()}`;
      } else if (props.type === 'constructive') {
          classSet += `${baseClasses} ${getConstructiveClasses()}`;
      } else if (props.toRoute && (props.disabled || autoLoadingDisabled.value)) {
        classSet += ` ${spanClasses}`;
      } else if (props.type === 'link' || props.toRoute) {
        classSet += ` ${getLinkClasses()}`;
      } else if (props.type === 'secondaryLink') {
        classSet += ` ${getSecondaryLinkClasses()}`;
      } else if (props.type === 'secondary') {
        classSet += `${baseClasses} ${getSecondaryClasses()}`;
      } else if (props.type === 'icon') {
        classSet += ` ${getIconClasses()}`;
      } else {
        classSet += `${baseClasses} ${getDefaultClasses()}`;
      }

      return classSet;
    });

    return {
      onClick,
      autoLoadingDisabled,
      buttonTag,
      buttonClasses,
      button,
    };
  },
});

export { ButtonType };
</script>
