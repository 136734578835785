import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "m-0 font-normal sm:font-bold" }
const _hoisted_2 = { class: "w-full grow overflow-auto sm:px-8 px-4 pb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StackSpacer = _resolveComponent("StackSpacer")!
  const _component_TopStrip = _resolveComponent("TopStrip")!
  const _component_VerticalStack = _resolveComponent("VerticalStack")!
  const _component_OverlayBackground = _resolveComponent("OverlayBackground")!

  return (_openBlock(), _createBlock(_component_OverlayBackground, null, {
    default: _withCtx(() => [
      _createVNode(_component_VerticalStack, {
        spacing: null,
        class: _normalizeClass(["border h-full border-border bg-background rounded-lg shadow-2xl py-0 overflow-hidden", {
      'sm:w-5/6 md:w-4/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 w-5/6': _ctx.size === 'medium',
      'sm:w-5/6 md:w-5/6 lg:w-4/5 xl:w-4/5 2xl:w-4/5 w-5/6': _ctx.size === 'large',
      'sm:w-5/6 md:w-3/5 lg:w-2/5 xl:w-2/6 2xl:w-2/6 w-5/6': _ctx.size === 'small',
      }])
      }, {
        default: _withCtx(() => [
          (_ctx.showMenu)
            ? (_openBlock(), _createBlock(_component_TopStrip, {
                key: 0,
                class: "h-14 z-30 shrink-0 grow-0 w-full",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
                  _createVNode(_component_StackSpacer, { size: "stretch" }),
                  (_ctx.showCloseButton)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: "/icons/close.svg",
                        alt: "close",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)), ["stop"])),
                        class: "h-4 aspect-square cursor-pointer grow-0"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", { id: "modal-content" })
          ])
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }))
}