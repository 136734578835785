import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { spacing: null }
const _hoisted_2 = ["type", "placeholder", "value", "maxlength", "autocomplete", "disabled", "accept"]
const _hoisted_3 = ["placeholder", "value", "disabled", "maxlength"]
const _hoisted_4 = {
  key: 2,
  class: "text-xs text-foreground-light ml-0.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type !== 'textarea')
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          ref: "inputElement",
          class: _normalizeClass(["default-input p-2 w-full", { 'border-error shadow-error-light': _ctx.error }]),
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          maxlength: _ctx.maxLength,
          autocomplete: _ctx.autocomplete,
          disabled: _ctx.disabled,
          accept: _ctx.type !== 'textarea' ? _ctx.accept : null,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event)))
        }, null, 42, _hoisted_2))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          ref: "textareaElement",
          class: _normalizeClass(["default-input p-2 w-full", { 'border-error shadow-error-light': _ctx.error }]),
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          disabled: _ctx.disabled,
          maxlength: _ctx.maxLength,
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('change', $event)))
        }, null, 42, _hoisted_3)),
    (_ctx.remainingCharsText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.remainingCharsText), 1))
      : _createCommentVNode("", true)
  ]))
}